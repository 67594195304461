<template>
  <div class="header-mobile__wrapper">
    <div class="header-mobile__gap-filler"></div>
    <a-header-mobile-top-section
      :is-menu-expanded="isMenuExpanded"
      @input="changeMenuVisibility"
      @loaded="onMenuComponentLoad"
    />

    <collapse-transition>
      <!-- ToDo: we always show mobile header menu for crawlers. Do not v-if -->
      <div
        v-show="isMenuExpanded && isMenuLoaded"
        v-on-clickaway="handleExpandedMenuClickaway"
      >
        <a-header-mobile-expanded
          :is-menu-expanded="isMenuExpanded"
          :menu-list="menuList"
          @onCloseMenu="closeMenu"
        />
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE } from 'enums/data-attributes'
import { THROTTLE_DEFAULT_DURATION } from 'enums/settings'
import { mapGetters } from 'vuex'
import { setHeaderDataToState } from '@/utils/helpers/getHeaderData'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { hasParentWith } from '@fmpedia/helpers'

export const CLOSE_MOBILE_HEADER_MENU_EVENT = 'CLOSE_MOBILE_HEADER_MENU_EVENT'
const SCROLL_TO_HEADER_DURATION = 300

export default {
  name: 'AHeaderMobile',
  mixins: [hydrationHelpers],
  components: {
    AHeaderMobileTopSection: hydrateWhenIdle(
      () => import('./AHeaderMobileTopSection'),
      { props: ['is-menu-expanded'] }
    ),
    AHeaderMobileExpanded: hydrateWhenVisible(
      () => import('./AHeaderMobileExpanded'),
      { props: ['is-menu-expanded', 'menu-list'] }
    )
  },
  props: {
    menuList: {
      type: Array,
      required: true
    }
  },
  directives: { onClickaway },
  data() {
    return {
      isMenuExpanded: false,
      isMenuLoaded: false,
      timeoutId: null
    }
  },
  computed: {
    ...mapGetters({
      isHeaderAtTop: 'isHeaderAtTop',
      isPageNavigationInProgress: 'isPageNavigationInProgress'
    })
  },
  watch: {
    isPageNavigationInProgress(newVal) {
      if (!newVal) return

      this.closeMenu()
    }
  },
  methods: {
    closeMenu() {
      if (!this.isMenuExpanded) return

      this.$_hydrationHelpers_showScroll()
      this.isMenuExpanded = false
    },
    changeMenuVisibility(val) {
      clearTimeout(this.timeoutId)

      if (!val) {
        this.closeMenu()
        return
      }

      /**
       * We need to update the header data in state here to avoid rare bugs when
       * the data in store is outdated
       *
       * Bug example: https://adraba.atlassian.net/browse/FMP-16571
       */
      setHeaderDataToState({ store: this.$store })

      if (!this.isHeaderAtTop) {
        this.$scrollTo(`.header-mobile__wrapper`, SCROLL_TO_HEADER_DURATION)

        /**
         * TODO investigate why we need to wait for THROTTLE_DEFAULT_DURATION
         * (or longer) before hiding the scroll (otherwise there is a content
         * jumping bug when mobile header menu opens)
         */
        this.timeoutId = setTimeout(async () => {
          this.isMenuExpanded = val
        }, SCROLL_TO_HEADER_DURATION + THROTTLE_DEFAULT_DURATION)

        return
      }

      this.isMenuExpanded = val
    },
    checkIfMenuOpenBtnClicked(e) {
      return hasParentWith(e.target, {
        attribute: HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE
      })
    },
    handleExpandedMenuClickaway(e) {
      if (this.checkIfMenuOpenBtnClicked(e) || !this.isMenuExpanded) return

      this.closeMenu()
    },
    onMenuComponentLoad(isLoaded) {
      this.isMenuLoaded = isLoaded
      this.$emit('loaded')
    }
  },
  beforeMount() {
    this.$bus.$on(CLOSE_MOBILE_HEADER_MENU_EVENT, this.closeMenu)
  },
  mounted() {
    this.$emit('mounted')
  },
  beforeDestroy() {
    this.$bus.$off(CLOSE_MOBILE_HEADER_MENU_EVENT)
    clearTimeout(this.timeoutId)
  }
}
</script>

<style lang="scss" scoped>
.header-mobile__wrapper {
  width: 100%;
  color: white;
  padding: 0 20px;

  .header-mobile__gap-filler {
    @include top-gap-filler(
      $background: linear-gradient(to right, #79b0ea, #5356d4),
      $z-index: 1
    );
  }
}
</style>
